<template>
  <div class="personnelHolography-detail">
    <head-layout
      head-title="相关方人员全息"
      :head-btn-options="headBtnOptions"
      @head-cancel="headCancel"
    ></head-layout>
    <div style="height: calc(100% - 52px)">
      <el-tabs
        tab-position="left"
        type="border-card"
        v-model="activeName"
        @tab-click="handleTabClick"
      >
        <el-tab-pane label="人员基本信息" name="personnelBaseInfo">
          <personnelBaseInfo ref="personnelBaseInfo"></personnelBaseInfo>
        </el-tab-pane>
        <el-tab-pane label="项目记录" name="projectRecord">
          <projectRecord ref="projectRecord"></projectRecord>
        </el-tab-pane>
        <el-tab-pane label="出入境记录" name="entryExitRecord">
          <entryExitRecord ref="entryExitRecord"></entryExitRecord>
        </el-tab-pane>
        <el-tab-pane label="人员证照" name="personnelLicence">
          <personnelLicence ref="personnelLicence"></personnelLicence>
        </el-tab-pane>
        <el-tab-pane label="培训记录" name="trainRecord">
          <trainRecord ref="trainRecord"></trainRecord>
        </el-tab-pane>
        <el-tab-pane label="考试记录" name="examRecord">
          <examRecord ref="examRecord"></examRecord>
        </el-tab-pane>
        <el-tab-pane label="参会记录" name="meetingRecord">
          <meetingRecord ref="meetingRecord"></meetingRecord>
        </el-tab-pane>
        <el-tab-pane label="奖惩记录" name="jiangchengRecord">
          <jiangchengRecord ref="jiangchengRecord"></jiangchengRecord>
        </el-tab-pane>
        <el-tab-pane label="黑名单" name="blackList">
          <blackList ref="blackList"></blackList>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import personnelBaseInfo from "../personnelHolography/components/personnelBaseInfo.vue";
import projectRecord from "../personnelHolography/components/projectRecord.vue";
import entryExitRecord from "../personnelHolography/components/entryExitRecord.vue";
import personnelLicence from "../personnelHolography/components/personnelLicence.vue";
import trainRecord from "../personnelHolography/components/trainRecord.vue";
import examRecord from "../personnelHolography/components/examRecord.vue";
import meetingRecord from "../personnelHolography/components/meetingRecord.vue";
import jiangchengRecord from "../personnelHolography/components/jiangchengRecord.vue";
import blackList from "./components/blackList.vue";

export default {
  components: {
    personnelBaseInfo,
    projectRecord,
    entryExitRecord,
    personnelLicence,
    trainRecord,
    examRecord,
    meetingRecord,
    jiangchengRecord,
    blackList,
  },
  data() {
    return {
      activeName: "personnelBaseInfo",
      formUserId: "",
      formId: "",
      pageType: "",
      opType: "",
    };
  },
  mounted() {
    let type = this.$route.query.type;
    let id = this.$route.query.id;
    let userId = this.$route.query.userId;
    this.pageType = type;
    this.formUserId = userId;
    this.formId = id;
    this.opType = this.$route.query.opType;
    this.handleTabClick();
  },
  computed: {
    headBtnOptions() {
      let result = [];

      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },
  },
  methods: {
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    handleTabClick() {
      if (this.activeName == "personnelBaseInfo") {
        this.$refs[this.activeName].init(this.formId, this.pageType, this.opType);
      } else {
        this.$refs[this.activeName].init(this.formUserId, this.pageType, this.opType);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.personnelHolography-detail {
  width: 100%;
  height: 100%;
  ::v-deep .el-tabs {
    width: 100%;
    height: 100%;
  }
  ::v-deep .el-tabs__header.is-left {
    min-width: 130px !important;
  }
  ::v-deep .el-tabs--border-card {
    background-color: #f5f7fa;
  }
  ::v-deep .el-tabs__content {
    height: 100%;
    background-color: #fff;
    ::v-deep .el-tab-pane {
      height: 100% !important;
    }
  }

  .formContentBox {
    padding: 12px 0;
    height: calc(100% - 76px);
  }
}
</style>
